<template>
    <section class="container">
        <div class="row">
            <div class="col-lg-8">
                <div class="row">
                    <div class="col-12">
                        <h5 class="mb-3 float-start covid-text">Covid-19 Questionnaire</h5>
                        <h5 class="mb-3 float-end covid-text-no">No</h5>
                        <h5 class="mb-3 float-end covid-text-yes">Yes</h5>
                    </div>
                    <div class="col-12">
                        <div class="row mb-2" v-for="point in covid_points" :key="point.id">
                            <div class="col-8">
                                <label class="form-check-label covid-label">{{point.points}}</label>
                            </div>
                            <div class="col-2 text-center">
                                <input v-model="point.value" value="yes" class="form-check-input" type="radio">
                            </div>
                            <div class="col-2">
                                <input v-model="point.value" value="no" class="form-check-input" type="radio">
                            </div>
                        </div>
                    </div>

                    <!-- <div class="col-12">
                        <h5 class="mb-2 mb-sm-3 mt-4 fs-18">Where is the service rendered?</h5>
                    </div>
                    <div class="col-12">
                        <div class="form-check form-check-inline">
                            <input v-model="booking_.render_location" value="1" class="form-check-input" type="radio" name="rl" id="rl1" />
                            <label class="form-check-label" for="rl1"> Business location </label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input v-model="booking_.render_location" value="2" class="form-check-input" type="radio" name="rl" id="rl2" />
                            <label class="form-check-label" for="rl2"> Client preference </label>
                        </div>
                    </div> -->
                    <!-- <div class="col-12" v-if="booking_.render_location == '2'">
                        <div class="row">
                            <div class="form-group col-sm-6">
                                <input type="text" v-model="booking_.rendered_address" class="form-control" placeholder="Enter your address">
                            </div>
                            <div class="form-group col-sm-6">
                                <input type="text"
                                    class="form-control"
                                    placeholder="Apt/Unit/Suite (optional)"
                                    v-model="booking_.rendered_address_apt" />
                            </div>
                            <span style="color:#DC143C; display:none">Whoops! this location is out of our service area.</span>
                        </div>
                    </div> -->

                    <div class="col-12 mt-4">
                        <h4 class="fs-18">Payment Information</h4>
                        <!-- <h6 class="mb-3 mt-2">Credit/ Debit Card <img src="@/assets/images/cards/credit-cards-logos.png"></h6> -->
                    </div>
                    <StripeCard :cardData="updateCard" />

                    <!-- <div class="col-12">
                        <h5 class="mb-3 mt-4">Note: </h5>
                    </div>
                    <div class="col-12">
                        <div class="cancelation-policy">
                            <ul>
                                <li>General OnDaQ <a href="#">Cancellation Policy</a> and <a href="#">Terms & Conditions</a> apply.</li>
                                <li>The platform does not allow rescheduling any booking within 24hrs of the booking time.</li>
                                <li>If you do not show up for getting the service you booked on the date and time of the booking, and you do not cancel or reschedule it 24hrs before the booking, you will automatically fall into "Same Day Cancellation" and "Harsh Cancellation Policy" of the business will be applied unless the business has set it to free.</li>
                                <li>System will wait for 10 minutes from the start time of the booking before it is considered Cancelled.</li>
                            </ul>
                        </div>
                    </div> -->
                </div>
            </div>
            <div class="col-lg-4 mt-5 mt-lg-0">
                <div class="row">
                    <div class="p-3 col-12" style="border: 1px solid #ddd">
                        <div class="row booking-summary-head m-0">
                            <div class="col-12 p-0" >
                                <h2 class="fs-18">Booking Summary</h2>
                                <p class="mb-0 fs-14">Appointment with <b>{{booking_.business_name}}</b></p>
                                <p class="mb-0 text-muted fs-15 fs-14">By {{booking_.professional.name}}</p>
                                <p class="date fs-14"><fa :icon="['far','clock']" /> {{changeDateFormate(booking_.date,'dddd, MMMM DD, YYYY')}} {{booking_.time_slot}}</p>
                            </div>
                        </div>
                        <div class="row my-3 mb-1 booking-summary-services-head">
                            <div class="col-6 fw-bold">Services</div>
                            <!-- <div class="col-6 text-end fw-bold">Cost</div> -->
                        </div>
                        <div v-if="booking_.is_deal">
                            <div class="row booking-summary-services" v-for="(service,index) in booking_.deal.deal_services" :key="index">
                                <div class="col-9">
                                    <div class="d-flex">
                                        <!-- <div class="flex-shrink-0">
                                            <img src="@/assets/images/icons/avatr.png" alt="">
                                        </div> -->
                                        <div class="flex-grow-1">
                                            <div class="serv-name service_text">{{service.service.title}}</div>
                                            <!-- <div class="prov-name">Muhammad Usman</div> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="col-3 ps-0 text-end">
                                    <div class="serv-name service_text">${{service.business_service.cost}}</div>
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <div class="row booking-summary-services" v-for="(service,index) in booking_.services" :key="index">
                                <div class="col-9">
                                    <div class="d-flex">
                                        <!-- <div class="flex-shrink-0">
                                            <img src="@/assets/images/icons/avatr.png" alt="">
                                        </div> -->
                                        <div class="flex-grow-1">
                                            <div class="serv-name service_text">{{service.title}}</div>
                                            <!-- <div class="prov-name">Muhammad Usman</div> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="col-3 ps-0 text-end">
                                    <div class="serv-name service_text">${{service.cost}}</div>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-3 booking-summary-subtotal mx-0">
                            <div class="col-9 ps-0">
                                <span class="fw-bold">Sub Total</span>
                            </div>
                            <div class="col-3 px-0 text-end">
                                <span class="fw-bold">${{servicesTotal}}</span>
                            </div>
                        </div>
                        <div class="row mt-3 booking-summary-tax">
                            <div class="col-9">
                                <span>Estimated Tax</span>
                            </div>
                            <div class="col-3 ps-0 text-end">
                                <span>${{servicesTax}}</span>
                            </div>
                        </div>

                        <!-- <div class="row mt-3 booking-summary-subtotal mx-0">
                            <div class="col-9 ps-0">
                                <span class="fs-15">Items (3)</span>
                            </div>
                            <div class="col-3 px-0 text-end">
                                <span class="fs-15">$66.00</span>
                            </div>
                        </div>
                        <div class="row booking-summary-tax">
                            <div class="col-9">
                                <span class="fs-15">Estimated Tax</span>
                            </div>
                            <div class="col-3 ps-0 text-end">
                                <span class="fs-15">$15.00</span>
                            </div>
                        </div>
                        <div class="row booking-summary-discount">
                            <div class="col-9">
                                <span>You Saved</span>
                            </div>
                            <div class="col-3 ps-0 text-end">
                                <span>$10.00</span>
                            </div>
                        </div> -->
                        <div class="row mt-3 mx-0 booking-summary-total">
                            <div class="col-9 ps-0">
                                <span class="fw-bold">Total</span>
                            </div>
                            <div class="col-3 px-0 text-end">
                                <span class="fw-bold">${{total}}</span>
                            </div>
                        </div>
                        <!-- <div class="row mt-4 booking-summary-saved">
                            <div class="col-9">
                                <span>You Saved</span>
                            </div>
                            <div class="col-3 ps-0 text-end">
                                <span>$22.00</span>
                            </div>
                        </div> -->
                        <div class="row mt-3">
                            <div class="col-12 mb-2">
                                <a class="additional-note"> <span><i class="fa fa-plus"></i></span> Send a note <small>(optional)</small> </a>
                                <textarea v-model="note" class="form-control h-auto" rows="3" placeholder="Enter note here..." style="resize: none"></textarea>
                            </div>
                            <div class="col-12 mt-2" v-if="policy_statement">
                                <h6>Cancellation and No-Show Policy</h6>
                                <p class="text-muted" style="font-size: 13px" v-html="policy_statement"></p>
                                <!-- <p class="text-muted" style="font-size: 13px">If Service is canceled within <strong class="text-dark">48hours</strong> or No-Show at the scheduled time, you will be charged <strong class="text-dark">40%</strong> of service fees. This charge is non-refundable.</p> -->
                                <!-- <div class="form-check">
                                    <input v-model="booking_.accept_policy" id="cp" class="form-check-input" type="checkbox">
                                    <label class="form-check-label" for="cp">
                                        I agree to the <a href="#" data-toggle="modal" data-target="#cancelationPolicy"> Cancellation/ No-show policy</a>.
                                    </label>
                                </div> -->
                            </div>
                        </div>

                        <div class="row mt-3 justify-content-center">
                            <div class="col-lg-11 col-md-7 col-sm-9">
                                <button type='button' :disabled="btnLoader" v-on:click="booking" class='ond-btn-p w-100 float-end'>
                                    <span v-if="btnLoader">Loading... &nbsp;&nbsp;<i class="fa fa-refresh fa-spin"></i></span>
                                    <span v-else>Book Now</span>
                                </button>
                            </div>
                            <div class="col-lg-11 col-md-7 col-sm-9 mt-2">
                                <router-link :to="{name:'BookNow'}" class="ond-btn-w w-100 float-start text-center">Cancel</router-link>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 credit-info">
                        <div class="row">
                            <div class="col-1 p-0">
                                <img src="@/assets/images/cards/security.png" class="img-fluid" style="width: 50px">
                            </div>
                            <div class="col-11">
                                <p class="mb-0">By clicking “Book Now” it means you agree to <a href="#" data-toggle="modal" data-target="#cancelationPolicy"> Ondaq’s Terms of service</a> and <a href="#" data-toggle="modal" data-target="#cancelationPolicy"> Privacy Policy</a>.<br /> Note: Your card will not be charged when you book the appointment, although, your creditcard might be charged when starting the service or after completion of the service.</p>
                                <!-- <p class="mb-0">Note: Your card will not be charged when you book the appointment, although, your creditcard might be charged when starting the service or after completion of the service.</p> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import axios from 'axios'
    import $ from 'jquery'
    import moment from 'moment'
    import { useToast } from "vue-toastification";
    import StripeCard from '@/components/common/StripeCard.vue'

    export default {
        components: {
            StripeCard
        },
        setup() {
            const toast = useToast();
            return {toast}
        },
        data() {
            return {
                booking_:{},
                covid_points:[],
                policy_statement:'',
                card:'',
                note:'',
                btnLoader:false,
                /*payment_cards:[],
                card_:{
                    name:'',
                    address:"",
                    email:'',
                },
                data_:{
                    covidPoints:[],
                    card:'',
                    newCard:false,
                    render_location:'business',
                    policy:false,
                    service:[],
                    date:'',
                    time_slot:''
                    //render_locaion:'business'
                }*/
            }
        },
        created() {
            if (!this.$storage.getStorageSync('token')) {
                $("#loginModal").modal('show')
            }
            //alert(process.env.VUE_APP_TITLE)

            if (this.$storage.hasKey("cart")) {
                let data = this.$storage.getStorageSync("cart")
                let cart = data[this.$route.params.slug]
                let today_date = new Date().toJSON().slice(0,10).replace(/-/g,'-')
                //console.log(cart)

                if (cart != undefined && cart != '' && cart != null && cart.cart_date == today_date) {
                    // All Good
                    this.booking_ = cart
                    // this.booking_.rendered_address = ''
                    // this.booking_.rendered_address_apt = ''
                    // this.booking_.accept_policy = false
                    this.booking_.slug = this.$route.params.slug
                    console.log(this.booking_)
                    // this.booking_.provider = cart.professional
                    // this.booking_.date = cart.date

                }else{
                    this.$router.push({ name: 'BookNow', params: { slug: this.$route.params.slug } })
                }
            }else{
                this.$router.push({ name: 'BookNow', params: { slug: this.$route.params.slug } })
            }
            //console.log(this.booking_)


            // if (this.$storage.hasKey("cart")) {
            //     var cart = this.$storage.getStorageSync("cart")
            //     cart[this.$route.params.slug] = {
            //         service:[3,1],
            //         date:'2021-11-21'
            //     }
            //     this.$storage.setStorageSync("cart",cart)
            // }else{
            //     var data = {}
            //     data[this.$route.params.slug] = {
            //         service:[1,2],
            //         date:'2021-11-21'
            //     }
            //     this.$storage.setStorageSync("cart",data)
            // }
            //console.log(this.$storage.getStorageSync("cart"))

            this.covidPoints()
            this.getCancelationPolicy()
        },
        methods:{
            booking(){
                if (this.card != '' && this.card != undefined && this.card != null) {

                    this.booking_.card = this.card
                    this.booking_.covid_points = this.covid_points
                    this.booking_.booking_source = 'online'
                    this.booking_.booking_type = 2
                    this.booking_.note = this.note
                    this.btnLoader = true
                    console.log(this.booking_)
                    let thiss = this
                    axios.post('book_now',this.booking_).then(function (response) {
                        if (response.data.status == 'success') {
                            // Redirect to success page
                            thiss.$router.push({ name: 'BookingSuccess', params: { slug: thiss.$route.params.slug, booking_id: response.data.data } })
                        }
                        this.btnLoader = false
                    })
                    .catch(error => {
                        if (error.response) {
                            console.log(error.response.data.message)
                        }
                        this.btnLoader = false
                    });
                }else{
                    this.toast.error("Please choose payment card.")
                }
            },
            covidPoints(){
                let thiss = this
                axios.get('covid_points/2').then(function (response) {
                    //console.log(response.data)
                    if (response.data.status == 'success') {
                        thiss.covid_points = response.data.data
                    }
                })
                .catch(error => {
                    if (error.response) {
                        console.log(error.response.data.message)
                    }
                });
            },
            updateCard(data){
                this.card = data
            },
            changeDateFormate(date,formate){
                return moment(date).format(formate)
            },
            getCancelationPolicy(){
                let thiss = this
                axios.get('get_policy_statement/'+this.$route.params.slug+'/cancel_no-show').then(function (response) {
                    console.log(response.data.data)
                    if (response.data.status == 'success') {
                        thiss.policy_statement = response.data.data
                    }
                })
                .catch(error => {
                    if (error.response) {
                        console.log(error.response.data.message)
                    }
                });
            }


            /*,
            paymentCards(){
                let thiss = this
                axios.get('payment_cards').then(function (response) {
                    console.log(response.data)
                    if (response.data.status == 'success') {
                        thiss.payment_cards = response.data.data
                    }
                })
                .catch(error => {
                    if (error.response) {
                        console.log(error.response.data.message)
                    }
                });
            }*/
        },
        computed: {
            servicesTotal: function () {
                //var data = this.booking_.services.filter(i => i.selected === true)
                if (this.booking_.is_deal) {
                    return this.booking_.deal.deal_services.reduce((acc, item) => parseFloat(acc) + parseFloat(item.business_service.cost), 0).toFixed(2);
                }else{
                    return this.booking_.services.reduce((acc, item) => parseFloat(acc) + parseFloat(item.cost), 0).toFixed(2);
                }

                //acc + item.value, 0
            },
            servicesTax: function () {
                if (this.booking_.is_deal) {
                    return this.booking_.deal.deal_services.reduce((acc, item) => (item.tax != null) ? parseFloat(acc) + parseFloat(item.business_service.tax) : parseFloat(acc)+0,0).toFixed(2);
                }else{
                    return this.booking_.services.reduce((acc, item) => (item.tax != null) ? parseFloat(acc) + parseFloat(item.tax) : parseFloat(acc)+0,0).toFixed(2);
                }
            },
            total: function () {
                return (parseFloat(this.servicesTotal) + parseFloat(this.servicesTax)).toFixed(2)
            }
        }
    }
</script>

<style scoped>

button.ond-btn-p , a.ond-btn-w{
    padding: 12px 25px;
}
    .covid-text{
        font-weight: 700
    }
    .covid-text-no{
        padding-right: 11%
    }
    .covid-text-yes{
        padding-right: 8%
    }
@media (max-width:575px) {
    .covid-text{
        font-size: 15px
    }
    .covid-text-no{
        font-size: 15px;
        font-weight: 600;
        padding-right: 6%
    }
    .covid-text-yes{
        font-size: 15px;
        font-weight: 600
    }
    .covid-label{
        font-size: 13px
    }
    .fs-18{
        font-size: 18px
    }
    .fs-14{
        font-size: 14px
    }
}
    .fs-15{
        font-size: 15px
    }
    .card_text{
        font-size: 14px
    }
    .card_text img{
        width: 175px;
        padding-left: 10px
    }
    .saved_cards .card-header{
        font-weight: 500
    }
    .saved_cards label{
        font-size: 13px
    }
    .saved_cards img{
        width: 35px
    }
    .booking-summary-discount{
        font-size: 14px;
        color: #6e6e6e
    }
    .service_text{
        font-size: 15px !important;
        color: #4a4a4a
    }


    .card-selection{
        background-color: #f3f3f3;
        background-image: linear-gradient(#fefefe, #f0f0f0);
        border: 2px solid #eee;
        border-radius: 5px;
        padding: 5px;
        width: 100%;
        max-width: 350px;
        margin: 0 15px 15px 0;
        display: -webkit-box;
        float: left
    }
    .card-selection.active{
        border: 2px solid #666 !important
    }
    .card-selection input{
        margin-top: 13px
    }
    .card-selection img{
        margin-top: 4px;
        max-width: 50px
    }
    .card-number{
        color: #828483;
        width: 100%;
        text-align: right;
        font-size: 14px;
        font-weight: bold
    }
    .card-type{
        color: #a2a2a2;
        width: 100%;
        text-align: right;
        font-size: 14px;
        font-weight: 500;
        margin: 0;
    }
    .saved_payments label{
        cursor: pointer
    }
    .saved_payments li:hover{
        background-color: #f1f1f1
    }
    .checkout{
        padding: 45px 175px;
        border-top: 1px solid #eee;
    }

    .booking-summary-head{
        text-align: center;
        border-bottom: 1px solid rgba(0,0,0,.15)
    }
    .booking-summary-head .date{
        color: #039620;
        font-size: 14px
    }
    .credit-info{
        border: 1px solid rgba(0, 0, 0, 0.15);
        border-top: none;
        padding: 20px;
        /*margin-bottom: 50px;*/
        font-size: 12px;
        background-color: rgb(238, 238, 238)
    }
    .credit-info img{
        text-align: center
    }
    .booking-summary-services-head{
        font-size: 17px;
    }
    .booking-summary-services{
        padding: 8px 0;
    }
    .booking-summary-services img{
        width: 50px;
        height: 50px;
        border: 1px solid #ddd;
        border-radius: 50%;
    }
    .booking-summary-services .serv-name{
        font-size: 17px;
        margin-bottom: 1px;
        margin-top: 2px;
    }
    .booking-summary-services .prov-name{
        font-size: 14px;
        color: rgba(51,51,51,.5);
        text-transform: uppercase;
    }

    .booking-summary-subtotal div{
        font-size: 17px;
    }
    .booking-summary-total div{
        font-size: 22px;
    }
    .booking-summary-subtotal div, .booking-summary-total div{
        border-top: 1px solid rgba(0,0,0,.15);
        padding-top: 10px;
    }

    .booking-summary-tax div{
        font-size: 17px
    }
    .booking-summary-saved{
        font-size: 14px
    }
    .additional-note{
        font-size: 14px;
        color: #000000
    }
    .booking-summary-services{
        padding: 0
    }
</style>