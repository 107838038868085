<template>
    <MainAppContainer>
        <Checkout />
    </MainAppContainer>
</template>
<script>
import MainAppContainer from '@/components/containers/MainAppContainer.vue'
import Checkout from '@/components/booking/Checkout.vue'

export default {
  name: 'Home',
  components: {
    MainAppContainer,
    Checkout
  }
}
</script>